// categories.jsx
const categories = [
    {
        id: 1,
        name: "Skin Care",
        slug: "skin-care",
        image: "/images/categories/01.webp",
        href: "/category",
        title: "Best Skin Care Products",
        description: "Explore our range of skin care products for all skin types.",
        productsCount: 120,
        featured: true,
        subcategories: [
            {
                id: 110,
                name: "Moisturizers",
                slug: "moisturizers",
                image: "/images/sub/01.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
                products: [
                    {
                        id: 1,
                        name: 'MacBook Pro',
                        image: 'path_to_image',
                        price: '$2000',
                    },
                    // More products...
                ],
            },
            {
                id: 111,
                name: "Cleansers",
                slug: "cleansers",
                image: "/images/sub/02.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 112,
                name: "Cleansers",
                slug: "cleansers",
                image: "/images/sub/03.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 113,
                name: "Serum",
                slug: "serum",
                image: "/images/sub/04.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 114,
                name: "Masks",
                slug: "masks",
                image: "/images/sub/05.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 115,
                name: "Sun care",
                slug: "sun-care",
                image: "/images/sub/06.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 116,
                name: "Skin Tech Tools",
                slug: "skin-tech-tools",
                image: "/images/sub/07.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 117,
                name: "Trending",
                slug: "trending",
                image: "/images/sub/08.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
        ],
        sliders: [
            { id: 1, image: '/images/categorySlider/01.webp', href: '/page-id' },
            { id: 2, image: '/images/categorySlider/02.webp', href: '/page-id' },
            { id: 3, image: '/images/categorySlider/03.webp', href: '/page-id' },
            { id: 4, image: '/images/categorySlider/04.webp', href: '/page-id' },
        ],
        brands: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/brand-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/brand-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/brand-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/brand-id' },
        ],
        products: [
            {
                id: 1,
                name: 'Hair Dye Brush',
                url: '/product/1',
                coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
                description: 'This is the description for product 1.',
                images: [
                    '/images/products/coverImage1.jpg',
                    '/images/products/coverImage2.jpg',
                    '/images/products/coverImage3.jpg',
                ],
                shortDescription: 'Short description of product 1.',
                longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
                price: 60.61,
                quantity: 30,
                discount: true,
                priceAfterDiscount: 30,
            },
        ],
    },
    {
        id: 2,
        name: "Hair Care",
        slug: "hair-care",
        image: "/images/categories/02.webp",
        href: "/category",
        title: "Best Skin Care Products",
        description: "Explore our range of skin care products for all skin types.",
        productsCount: 120,
        featured: true,
        subcategories: [
            {
                id: 211,
                name: "Shampoo & Conditioner",
                slug: "shampoo-conditioner",
                image: "images/sub/02.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 212,
                name: "Hair Nourishment",
                slug: "hair-nourishment",
                image: "path/to/moisturizers-image.jpg",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 213,
                name: "Hair Styling",
                slug: "hair-styling",
                image: "path/to/moisturizers-image.jpg",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 214,
                name: "Hair Brushes & Accessories",
                slug: "hair-brushes-accessories",
                image: "path/to/moisturizers-image.jpg",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 215,
                name: "Hair Styling Devices",
                slug: "hair-styling-devices",
                image: "path/to/moisturizers-image.jpg",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 216,
                name: "Hair Coloring",
                slug: "hair-coloring",
                image: "path/to/moisturizers-image.jpg",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 217,
                name: "Salon Hair Care",
                slug: "salon-hair-care",
                image: "path/to/moisturizers-image.jpg",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 218,
                name: "Hair & Scalp Treatment",
                slug: "hair-scalp-treatment",
                image: "path/to/moisturizers-image.jpg",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            },
            {
                id: 219,
                name: "Professional Hair Care",
                slug: "professional-hair-care",
                image: "path/to/moisturizers-image.jpg",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            }
        ],
        sliders: [
            { id: 1, image: '/images/categorySlider/hair-01.webp', href: '/page-id' },
            { id: 2, image: '/images/categorySlider/hair-02.webp', href: '/page-id' },
            { id: 3, image: '/images/categorySlider/hair-03.webp', href: '/page-id' },
        ],
        brands: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/brand-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/brand-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/brand-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/brand-id' },
        ],
        products: [
            {
                id: 1,
                name: 'Hair Dye Brush',
                url: '/product/1',
                coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
                description: 'This is the description for product 1.',
                images: [
                    '/images/products/coverImage1.jpg',
                    '/images/products/coverImage2.jpg',
                    '/images/products/coverImage3.jpg',
                ],
                shortDescription: 'Short description of product 1.',
                longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
                price: 60.61,
                quantity: 30,
                discount: true,
                priceAfterDiscount: 30,
            },
        ],
    },
    {
        id: 3,
        name: "Makeup",
        slug: "makeup",
        image: "/images/categories/03.webp",
        href: "/category",
        title: "Best Skin Care Products",
        description: "Explore our range of skin care products for all skin types.",
        productsCount: 120,
        featured: true,
        subcategories: [
            {
                id: 311,
                name: "Moisturizers",
                slug: "moisturizers",
                image: "images/sub/03.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            }
        ],
        sliders: [
            { id: 1, image: '/images/categorySlider/makeup-01.webp', href: '/page-id' },
            { id: 2, image: '/images/categorySlider/makeup-02.webp', href: '/page-id' },
        ],
        brands: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/brand-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/brand-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/brand-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/brand-id' },
        ],
        products: [
            {
                id: 1,
                name: 'Hair Dye Brush',
                url: '/product/1',
                coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
                description: 'This is the description for product 1.',
                images: [
                    '/images/products/coverImage1.jpg',
                    '/images/products/coverImage2.jpg',
                    '/images/products/coverImage3.jpg',
                ],
                shortDescription: 'Short description of product 1.',
                longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
                price: 60.61,
                quantity: 30,
                discount: true,
                priceAfterDiscount: 30,
            },
        ],
    },
    {
        id: 4,
        name: "Last Chance Offers",
        slug: "last-chance-offers",
        image: "/images/categories/04.webp",
        href: "/category",
        title: "Best Skin Care Products",
        description: "Explore our range of skin care products for all skin types.",
        productsCount: 120,
        featured: true,
        subcategories: [
            {
                id: 411,
                name: "Moisturizers",
                slug: "moisturizers",
                image: "images/sub/04.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            }
        ],
        sliders: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/page-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/page-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/page-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/page-id' },
        ],
        brands: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/brand-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/brand-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/brand-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/brand-id' },
        ],
        products: [
            {
                id: 1,
                name: 'Hair Dye Brush',
                url: '/product/1',
                coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
                description: 'This is the description for product 1.',
                images: [
                    '/images/products/coverImage1.jpg',
                    '/images/products/coverImage2.jpg',
                    '/images/products/coverImage3.jpg',
                ],
                shortDescription: 'Short description of product 1.',
                longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
                price: 60.61,
                quantity: 30,
                discount: true,
                priceAfterDiscount: 30,
            },
        ],
    },
    {
        id: 5,
        name: "Global Products",
        slug: "global-products",
        image: "/images/categories/05.webp",
        href: "/category",
        title: "Best Skin Care Products",
        description: "Explore our range of skin care products for all skin types.",
        productsCount: 120,
        featured: true,
        subcategories: [
            {
                id: 511,
                name: "Moisturizers",
                slug: "moisturizers",
                image: "images/sub/05.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            }
        ],
        sliders: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/page-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/page-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/page-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/page-id' },
        ],
        brands: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/brand-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/brand-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/brand-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/brand-id' },
        ],
        products: [
            {
                id: 1,
                name: 'Hair Dye Brush',
                url: '/product/1',
                coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
                description: 'This is the description for product 1.',
                images: [
                    '/images/products/coverImage1.jpg',
                    '/images/products/coverImage2.jpg',
                    '/images/products/coverImage3.jpg',
                ],
                shortDescription: 'Short description of product 1.',
                longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
                price: 60.61,
                quantity: 30,
                discount: true,
                priceAfterDiscount: 30,
            },
        ],
    },
    {
        id: 6,
        name: "Mom & Baby",
        slug: "mom-baby",
        image: "/images/categories/06.webp",
        href: "/category",
        title: "Best Skin Care Products",
        description: "Explore our range of skin care products for all skin types.",
        productsCount: 120,
        featured: true,
        subcategories: [
            {
                id: 611,
                name: "Moisturizers",
                slug: "moisturizers",
                image: "images/sub/06.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            }
        ],
        sliders: [
            { id: 1, image: '/images/categorySlider/mom-01.webp', href: '/page-id' },
            { id: 2, image: '/images/categorySlider/mom-02.webp', href: '/page-id' },
            { id: 3, image: '/images/categorySlider/mom-03.webp', href: '/page-id' },
            { id: 4, image: '/images/categorySlider/mom-04.webp', href: '/page-id' },
            { id: 5, image: '/images/categorySlider/mom-05.webp', href: '/page-id' },
        ],
        brands: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/brand-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/brand-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/brand-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/brand-id' },
        ],
        products: [
            {
                id: 1,
                name: 'Hair Dye Brush',
                url: '/product/1',
                coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
                description: 'This is the description for product 1.',
                images: [
                    '/images/products/coverImage1.jpg',
                    '/images/products/coverImage2.jpg',
                    '/images/products/coverImage3.jpg',
                ],
                shortDescription: 'Short description of product 1.',
                longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
                price: 60.61,
                quantity: 30,
                discount: true,
                priceAfterDiscount: 30,
            },
        ],
    },
    {
        id: 7,
        name: "Diapers",
        slug: "diapers",
        image: "/images/categories/07.webp",
        href: "/category",
        title: "Best Skin Care Products",
        description: "Explore our range of skin care products for all skin types.",
        productsCount: 120,
        featured: true,
        subcategories: [
            {
                id: 711,
                name: "Moisturizers",
                slug: "moisturizers",
                image: "images/sub/07.webp",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            }
        ],
        sliders: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/page-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/page-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/page-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/page-id' },
        ],
        brands: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/brand-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/brand-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/brand-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/brand-id' },
        ],
        products: [
            {
                id: 1,
                name: 'Hair Dye Brush',
                url: '/product/1',
                coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
                description: 'This is the description for product 1.',
                images: [
                    '/images/products/coverImage1.jpg',
                    '/images/products/coverImage2.jpg',
                    '/images/products/coverImage3.jpg',
                ],
                shortDescription: 'Short description of product 1.',
                longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
                price: 60.61,
                quantity: 30,
                discount: true,
                priceAfterDiscount: 30,
            },
        ],
    },
    {
        id: 8,
        name: "Treatments",
        slug: "treatments",
        image: "/images/categories/08.webp",
        href: "/category",
        title: "Best Skin Care Products",
        description: "Explore our range of skin care products for all skin types.",
        productsCount: 120,
        featured: true,
        subcategories: [
            {
                id: 811,
                name: "Moisturizers",
                slug: "moisturizers",
                image: "path/to/moisturizers-image.jpg",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            }
        ],
        sliders: [
            { id: 1, image: '/images/categorySlider/tre-01.webp', href: '/page-id' },
            { id: 2, image: '/images/categorySlider/tre-02.webp', href: '/page-id' },
            { id: 3, image: '/images/categorySlider/tre-03.webp', href: '/page-id' },
        ],
        brands: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/brand-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/brand-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/brand-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/brand-id' },
        ],
        products: [
            {
                id: 1,
                name: 'Hair Dye Brush',
                url: '/product/1',
                coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
                description: 'This is the description for product 1.',
                images: [
                    '/images/products/coverImage1.jpg',
                    '/images/products/coverImage2.jpg',
                    '/images/products/coverImage3.jpg',
                ],
                shortDescription: 'Short description of product 1.',
                longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
                price: 60.61,
                quantity: 30,
                discount: true,
                priceAfterDiscount: 30,
            },
        ],
    },
    {
        id: 9,
        name: "Daily Essentials",
        slug: "daily-essentials",
        image: "/images/categories/09.webp",
        href: "/category",
        title: "Best Skin Care Products",
        description: "Explore our range of skin care products for all skin types.",
        productsCount: 120,
        featured: true,
        subcategories: [
            {
                id: 911,
                name: "Moisturizers",
                slug: "moisturizers",
                image: "path/to/moisturizers-image.jpg",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            }
        ],
        sliders: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/page-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/page-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/page-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/page-id' },
        ],
        brands: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/brand-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/brand-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/brand-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/brand-id' },
        ],
        products: [
            {
                id: 1,
                name: 'Hair Dye Brush',
                url: '/product/1',
                coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
                description: 'This is the description for product 1.',
                images: [
                    '/images/products/coverImage1.jpg',
                    '/images/products/coverImage2.jpg',
                    '/images/products/coverImage3.jpg',
                ],
                shortDescription: 'Short description of product 1.',
                longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
                price: 60.61,
                quantity: 30,
                discount: true,
                priceAfterDiscount: 30,
            },
        ],
    },
    {
        id: 10,
        name: "Healthy Food",
        slug: "healthy-food",
        image: "/images/categories/10.webp",
        href: "/category",
        title: "Best Skin Care Products",
        description: "Explore our range of skin care products for all skin types.",
        productsCount: 120,
        featured: true,
        subcategories: [
            {
                id: 1011,
                name: "Moisturizers",
                slug: "moisturizers",
                image: "path/to/moisturizers-image.jpg",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            }
        ],
        sliders: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/page-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/page-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/page-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/page-id' },
        ],
        brands: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/brand-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/brand-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/brand-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/brand-id' },
        ],
        products: [
            {
                id: 1,
                name: 'Hair Dye Brush',
                url: '/product/1',
                coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
                description: 'This is the description for product 1.',
                images: [
                    '/images/products/coverImage1.jpg',
                    '/images/products/coverImage2.jpg',
                    '/images/products/coverImage3.jpg',
                ],
                shortDescription: 'Short description of product 1.',
                longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
                price: 60.61,
                quantity: 30,
                discount: true,
                priceAfterDiscount: 30,
            },
        ],
    },
    {
        id: 11,
        name: "Vitamins",
        slug: "vitamins",
        image: "/images/categories/11.webp",
        href: "/category",
        title: "Best Skin Care Products",
        description: "Explore our range of skin care products for all skin types.",
        productsCount: 120,
        featured: true,
        subcategories: [
            {
                id: 1111,
                name: "Moisturizers",
                slug: "moisturizers",
                image: "path/to/moisturizers-image.jpg",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            }
        ],
        sliders: [
            { id: 1, image: '/images/categorySlider/vit-01.webp', href: '/page-id' },
            { id: 2, image: '/images/categorySlider/vit-02.webp', href: '/page-id' },
            { id: 3, image: '/images/categorySlider/vit-03.webp', href: '/page-id' },
            { id: 4, image: '/images/categorySlider/vit-04.webp', href: '/page-id' },
            { id: 4, image: '/images/categorySlider/vit-04.webp', href: '/page-id' },
        ],
        brands: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/brand-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/brand-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/brand-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/brand-id' },
        ],
        products: [
            {
                id: 1,
                name: 'Hair Dye Brush',
                url: '/product/1',
                coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
                description: 'This is the description for product 1.',
                images: [
                    '/images/products/coverImage1.jpg',
                    '/images/products/coverImage2.jpg',
                    '/images/products/coverImage3.jpg',
                ],
                shortDescription: 'Short description of product 1.',
                longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
                price: 60.61,
                quantity: 30,
                discount: true,
                priceAfterDiscount: 30,
            },
        ],
    },
    {
        id: 12,
        name: "Health Care",
        slug: "health-care",
        image: "/images/categories/12.webp",
        href: "/category",
        title: "Best Skin Care Products",
        description: "Explore our range of skin care products for all skin types.",
        productsCount: 120,
        featured: true,
        subcategories: [
            {
                id: 2111,
                name: "Moisturizers",
                slug: "moisturizers",
                image: "path/to/moisturizers-image.jpg",
                title: "Top Moisturizers",
                description: "Hydrate your skin with our top moisturizers.",
                productsCount: 50,
                featured: true,
            }
        ],
        sliders: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/page-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/page-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/page-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/page-id' },
        ],
        brands: [
            { id: 1, image: '/images/sections/spen01.webp', href: '/brand-id' },
            { id: 2, image: '/images/sections/spen02.webp', href: '/brand-id' },
            { id: 3, image: '/images/sections/spen03.webp', href: '/brand-id' },
            { id: 4, image: '/images/sections/spen04.webp', href: '/brand-id' },
        ],
        products: [
            {
                id: 1,
                name: 'Hair Dye Brush',
                url: '/product/1',
                coverImage: '/images/products/coverImage1.jpg' || '/images/default.jpg',
                description: 'This is the description for product 1.',
                images: [
                    '/images/products/coverImage1.jpg',
                    '/images/products/coverImage2.jpg',
                    '/images/products/coverImage3.jpg',
                ],
                shortDescription: 'Short description of product 1.',
                longDescription: 'A hairbrush is a brush with rigid (hard or inflexible) or light and soft spokes used in hair care for smoothing, styling, and detangling human hair, or for grooming an animal\'s fur. It can also be used for styling in combination with a curling iron or hair dryer.',
                price: 60.61,
                quantity: 30,
                discount: true,
                priceAfterDiscount: 30,
            },
        ],
    },
];

export default categories;
