// data.jsx
const bannerData = [
    {
        id: 1,
        title: "Slide 1",
        image: "/images/bannerslider/bannerSlide01.webp",
        href: "/page1"
    }
];

export default bannerData;
